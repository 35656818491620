import React, { useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axiosConfig';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            console.log('Attempting login for email:', email);
            const response = await axios.post('/api/users/login', { email, password });
            console.log('Login response:', response);
            
            if (response.data) {
                const sessionId = response.headers['x-session-id'];
                console.log('Received session ID:', sessionId);
                
                if (sessionId) {
                    localStorage.setItem('sessionId', sessionId);
                    console.log('Session ID saved to localStorage');
                } else {
                    console.warn('No session ID received from server');
                }
                
                localStorage.setItem('user', JSON.stringify(response.data));
                console.log('User data saved to localStorage:', response.data);
                
                navigate(response.data.role === 'ROLE_ADMIN' ? '/register-client' : '/mypage');
            }
        } catch (error) {
            console.error('ログイン失敗:', error);
            console.error('Error response:', error.response);
            setError('ログインに失敗しました。メールアドレスとパスワードを確認してください。');
        }
    };

    return (
        <Container fluid className="vh-100 d-flex align-items-center justify-content-center bg-light">
            <Row className="w-100">
                <Col xs={12} md={6} lg={4} className="mx-auto">
                    <div className="bg-white p-5 rounded-lg shadow-sm">
                        <h2 className="text-center mb-4 text-primary">ログイン</h2>
                        <Form onSubmit={handleLogin}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>メールアドレス</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="メールアドレスを入力"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>パスワード</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="パスワードを入力"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" className="mt-3">
                                    ログイン
                                </Button>
                            </div>
                        </Form>
                        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default LoginPage;