import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './components/HomePage';
import LoginPage from './components/LoginPage';
import MyPage from './components/MyPage';
import RegisterClientPage from './components/RegisterClientPage';
import SettingPage from './components/SettingPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/mypage" element={<MyPage />} />
        <Route path="/register-client" element={<RegisterClientPage />} />
        <Route path="/settings" element={<SettingPage />} />
        <Route path="/logout" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;