import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import instance from '../api/axiosConfig';
import styles from '../styles/RegisterClientPage.module.css';

function RegisterClientPage() {
    const [client, setClient] = useState({
        companyName: '',
        representativeName: '',
        contactPersonName: '',
        address: '',
        phone: '',
        email: '',
        websiteUrl: '',
        contractStartDate: '',
        meetingHistory: '',
        referralRecord: '',
        priority: '中', // デフォルト値を設定
        users: [
            { username: '', email: '', password: '', role: 'ROLE_USER' }
        ]
    });
    const navigate = useNavigate();

    const handleClientChange = (e) => {
        const { name, value } = e.target;
        setClient({ ...client, [name]: value });
    };

    const handleUserChange = (index, e) => {
        const { name, value } = e.target;
        const users = [...client.users];
        users[index][name] = value;
        setClient({ ...client, users });
    };

    const addUser = () => {
        setClient({
            ...client,
            users: [...client.users, { username: '', email: '', password: '', role: 'ROLE_USER' }]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await instance.post('/api/clients/saveWithUsers', client);
            console.log('Client and users registered successfully:', response.data);
            alert('クライアントとユーザーが正常に登録されました');
            navigate('/mypage');  // 登録成功後にマイページへ遷移
        } catch (error) {
            console.error('Registration failed:', error);
            alert('登録に失敗しました: ' + (error.response?.data || error.message));
        }
    };

    const handleLogout = async () => {
        try {
            await instance.post('/api/users/logout');
            localStorage.removeItem('user');
            navigate('/');
        } catch (error) {
            console.error('ログアウトに失敗しました:', error);
            alert('ログアウトに失敗しました。もう一度お試しください。');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.title}>クライアント登録</h2>
                <div className={styles.navButtons}>
                    <Link to="/mypage" className={styles.button}>マイページ</Link>
                    <button onClick={handleLogout} className={styles.button}>ログアウト</button>
                </div>
            </div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                    <label className={styles.label}>会社名:</label>
                    <input className={styles.input} type="text" name="companyName" value={client.companyName} onChange={handleClientChange} required />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>代表者名:</label>
                    <input className={styles.input} type="text" name="representativeName" value={client.representativeName} onChange={handleClientChange} required />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>担当者名:</label>
                    <input className={styles.input} type="text" name="contactPersonName" value={client.contactPersonName} onChange={handleClientChange} required />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>住所:</label>
                    <input className={styles.input} type="text" name="address" value={client.address} onChange={handleClientChange} required />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>電話番号:</label>
                    <input className={styles.input} type="text" name="phone" value={client.phone} onChange={handleClientChange} required />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>メールアドレス:</label>
                    <input className={styles.input} type="email" name="email" value={client.email} onChange={handleClientChange} required />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>ウェブサイトURL:</label>
                    <input className={styles.input} type="text" name="websiteUrl" value={client.websiteUrl} onChange={handleClientChange} />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>契約開始日:</label>
                    <input className={styles.input} type="date" name="contractStartDate" value={client.contractStartDate} onChange={handleClientChange} required />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>会議履歴:</label>
                    <input className={styles.input} type="text" name="meetingHistory" value={client.meetingHistory} onChange={handleClientChange} />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>紹介記録:</label>
                    <input className={styles.input} type="text" name="referralRecord" value={client.referralRecord} onChange={handleClientChange} />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>優先度:</label>
                    <select className={styles.select} name="priority" value={client.priority} onChange={handleClientChange} required>
                        <option value="高">高</option>
                        <option value="中">中</option>
                        <option value="低">低</option>
                    </select>
                </div>
                <div className={styles.userSection}>
                    <h3 className={styles.userTitle}>ユーザー</h3>
                    {client.users.map((user, index) => (
                        <div key={index}>
                            <div className={styles.formGroup}>
                                <label className={styles.label}>ユーザー名:</label>
                                <input className={styles.input} type="text" name="username" value={user.username} onChange={(e) => handleUserChange(index, e)} required />
                            </div>
                            <div className={styles.formGroup}>
                                <label className={styles.label}>メールアドレス:</label>
                                <input className={styles.input} type="email" name="email" value={user.email} onChange={(e) => handleUserChange(index, e)} required />
                            </div>
                            <div className={styles.formGroup}>
                                <label className={styles.label}>パスワード:</label>
                                <input className={styles.input} type="password" name="password" value={user.password} onChange={(e) => handleUserChange(index, e)} required />
                            </div>
                            <div className={styles.formGroup}>
                                <label className={styles.label}>役割:</label>
                                <select
                                    className={styles.select}
                                    name="role"
                                    value={user.role}
                                    onChange={(e) => handleUserChange(index, e)}
                                    required
                                >
                                    <option value="ROLE_USER">一般ユーザー</option>
                                    <option value="ROLE_ADMIN">管理者</option>
                                </select>
                            </div>
                        </div>
                    ))}
                </div>
                <button className={`${styles.button} ${styles.addUserButton}`} type="button" onClick={addUser}>ユーザー追加</button>
                <button className={styles.button} type="submit">登録</button>
            </form>
        </div>
    );
}

export default RegisterClientPage;