import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../styles/HomePage.module.css';

const instance = axios.create({
    baseURL: 'http://133.167.37.200:8080',
    withCredentials: true
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            // セッションが切れた場合はログインページにリダイレクト
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

function HomePage() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkLoginStatus();
    }, []);

    const checkLoginStatus = async () => {
        try {
            const response = await instance.get('/api/users/check-login');
            setIsLoggedIn(response.data.isLoggedIn);
            setIsAdmin(response.data.isAdmin);
        } catch (error) {
            console.error('Error checking login status:', error);
        }
    };

    const handleLogout = async () => {
        try {
            await instance.post('/api/users/logout');
            setIsLoggedIn(false);
            setIsAdmin(false);
            window.location.reload();
        } catch (error) {
            console.error('ログアウトに失敗しました:', error);
            alert('ログアウトに失敗しました。もう一度お試しください。');
        }
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>オフィス管理システム</h1>
            <nav className={styles.nav}>
                {!isLoggedIn && (
                    <Link to="/login" className={styles.link}>ログイン</Link>
                )}
                {isLoggedIn && (
                    <div className={styles.loggedInLinks}>
                        <Link to="/mypage" className={styles.link}>マイページ</Link>
                        <button onClick={handleLogout} className={styles.link}>ログアウト</button>
                    </div>
                )}
                {isAdmin && (
                    <Link to="/register-client" className={styles.link}>クライアント登録</Link>
                )}
            </nav>
        </div>
    );
}

export default HomePage;