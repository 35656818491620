import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://hidaliudeapp.com';

const instance = axios.create({
    baseURL: baseURL,
    withCredentials: true
});

instance.interceptors.request.use(
    (config) => {
        const sessionId = localStorage.getItem('sessionId');
        if (sessionId) {
            config.headers['X-Session-ID'] = sessionId;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        const sessionId = response.headers['x-session-id'];
        if (sessionId) {
            localStorage.setItem('sessionId', sessionId);
            console.log('Session ID saved in interceptor:', sessionId);
        }
        return response;
    },
    (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            localStorage.removeItem('sessionId');
            localStorage.removeItem('user');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default instance;