import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaEye, FaHome, FaKey, FaSave } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/SettingPage.module.css';

const instance = axios.create({
    baseURL: 'https://hidaliudeapp.com',
    withCredentials: true
});

function SettingPage() {
    const [mode, setMode] = useState('menu');
    const [companyData, setCompanyData] = useState({
        companyName: '',
        representativeName: '',
        contactPersonName: '',
        address: '',
        phone: '',
        email: '',
    });
    const [editData, setEditData] = useState({});
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('useEffect triggered');
        fetchCompanyData();
    }, []);

    const fetchCompanyData = async () => {
        try {
            console.log('Fetching company data...');
            const response = await instance.get('/api/users/settings');
            console.log('Company data received:', response.data);
            setCompanyData(response.data);
            setEditData(response.data);
        } catch (error) {
            console.error('Error fetching company data:', error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    };

    const handleEditChange = useCallback((e) => {
        const { name, value } = e.target;
        setEditData(prev => {
            const newData = { ...prev, [name]: value };
            setIsDirty(JSON.stringify(newData) !== JSON.stringify(companyData));
            return newData;
        });
    }, [companyData]);

    const handlePasswordChange = (e) => {
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!isDirty) {
            setSuccess('変更はありません。');
            return;
        }

        const changedData = Object.keys(editData).reduce((acc, key) => {
            if (editData[key] !== companyData[key]) {
                acc[key] = editData[key];
            }
            return acc;
        }, {});

        try {
            await instance.put('/api/users/settings', changedData);
            setSuccess('会社情報が更新されました。');
            fetchCompanyData();
            setMode('menu');
            setIsDirty(false);
        } catch (error) {
            console.error('Error updating company data:', error);
            setError('会社情報の更新に失敗しました。');
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (passwordData.newPassword !== passwordData.confirmPassword) {
            setError('新しいパスワードと確認用パスワードが一致しません。');
            return;
        }

        try {
            const response = await instance.put('/api/users/change-password', {
                currentPassword: passwordData.currentPassword,
                newPassword: passwordData.newPassword
            });
            setSuccess('パスワードが正常に変更されました。');
            setPasswordData({
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            });
        } catch (error) {
            console.error('Error changing password:', error);
            setError('パスワードの変更に失敗しました。現在のパスワードが正しいか確認してください。');
        }
    };

    const renderMenu = () => (
        <div className={styles.menuContainer}>
            <div className={styles.menuButtons}>
                <button onClick={() => setMode('view')} className={styles.menuButton}>
                    <FaEye /> 会社情報を確認する
                </button>
                <button onClick={() => { setMode('edit'); setEditData({...companyData}); setIsDirty(false); }} className={styles.menuButton}>
                    <FaEdit /> 会社情報を編集する
                </button>
                <button onClick={() => setMode('password')} className={styles.menuButton}>
                    <FaKey /> ログインパスワードを変更する
                </button>
            </div>
            <button onClick={() => navigate('/mypage')} className={styles.backToMyPageButton}>
                <FaHome /> マイページに戻る
            </button>
        </div>
    );

    const renderView = () => {
        console.log('Rendering view, companyData:', companyData);
        return (
            <div className={styles.viewInfo}>
                <h3>会社情報</h3>
                <p><strong>会社名:</strong> <span>{companyData.companyName}</span></p>
                <p><strong>代表者名:</strong> <span>{companyData.representativeName}</span></p>
                <p><strong>担当者名:</strong> <span>{companyData.contactPersonName}</span></p>
                <p><strong>住所:</strong> <span>{companyData.address}</span></p>
                <p><strong>電話番号:</strong> <span>{companyData.phone}</span></p>
                <p><strong>メールアドレス:</strong> <span>{companyData.email}</span></p>
                <button onClick={() => setMode('menu')} className={styles.backButton}>戻る</button>
            </div>
        );
    };

    const renderEdit = () => (
        <form onSubmit={handleSubmitEdit} className={styles.form}>
            <div className={styles.formGroup}>
                <label htmlFor="companyName">会社名</label>
                <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={editData.companyName}
                    onChange={handleEditChange}
                    className={styles.input}
                />
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="representativeName">代表者名</label>
                <input
                    type="text"
                    id="representativeName"
                    name="representativeName"
                    value={editData.representativeName}
                    onChange={handleEditChange}
                    className={styles.input}
                />
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="contactPersonName">担当者名</label>
                <input
                    type="text"
                    id="contactPersonName"
                    name="contactPersonName"
                    value={editData.contactPersonName}
                    onChange={handleEditChange}
                    className={styles.input}
                />
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="address">住所</label>
                <input
                    type="text"
                    id="address"
                    name="address"
                    value={editData.address}
                    onChange={handleEditChange}
                    className={styles.input}
                />
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="phone">電話番号</label>
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={editData.phone}
                    onChange={handleEditChange}
                    className={styles.input}
                />
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="email">メールアドレス</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={editData.email}
                    onChange={handleEditChange}
                    className={styles.input}
                />
            </div>
            <div className={styles.buttonGroup}>
                <button 
                    type="submit" 
                    className={styles.submitButton}
                    disabled={!isDirty}
                >
                    <FaSave /> 保存
                </button>
                <button 
                    type="button" 
                    onClick={() => {
                        setMode('menu');
                        setEditData({...companyData});
                        setIsDirty(false);
                    }} 
                    className={styles.cancelButton}
                >
                    キャンセル
                </button>
            </div>
        </form>
    );

    const renderPasswordChange = () => (
        <form onSubmit={handlePasswordSubmit} className={styles.form}>
            <div className={styles.formGroup}>
                <label htmlFor="currentPassword">現在のパスワード</label>
                <input
                    type="password"
                    id="currentPassword"
                    name="currentPassword"
                    value={passwordData.currentPassword}
                    onChange={handlePasswordChange}
                    className={styles.input}
                />
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="newPassword">新しいパスワード</label>
                <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={passwordData.newPassword}
                    onChange={handlePasswordChange}
                    className={styles.input}
                />
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="confirmPassword">新しいパスワード（確認）</label>
                <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={passwordData.confirmPassword}
                    onChange={handlePasswordChange}
                    className={styles.input}
                />
            </div>
            <div className={styles.buttonGroup}>
                <button type="submit" className={styles.submitButton}>
                    <FaKey /> パスワードを変更
                </button>
                <button type="button" onClick={() => setMode('menu')} className={styles.cancelButton}>
                    キャンセル
                </button>
            </div>
        </form>
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.title}>設定</h2>
            </div>
            <div className={styles.card}>
                {error && <div className={styles.error}>{error}</div>}
                {success && <div className={styles.success}>{success}</div>}
                {mode === 'menu' && renderMenu()}
                {mode === 'view' && renderView()}
                {mode === 'edit' && renderEdit()}
                {mode === 'password' && renderPasswordChange()}
            </div>
        </div>
    );
}

export default SettingPage;