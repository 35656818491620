import React, { useCallback, useEffect, useState } from 'react';
import { FaClock, FaCog, FaSignOutAlt, FaYenSign } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../api/axiosConfig';
import styles from '../styles/MyPage.module.css';

const formatTime = (hours, minutes) => {
    return `${hours}時間${minutes}分`;
};

function MyPage() {
    const currentDate = new Date();
    const currentMonth = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    const [allTasks, setAllTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    const [totalMinutes, setTotalMinutes] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [clientName, setClientName] = useState('');
    const navigate = useNavigate();

    const filterTasks = useCallback(() => {
        let filtered = allTasks;
        if (selectedMonth) {
            filtered = allTasks.filter(task => {
                const taskDate = new Date(task.taskDate);
                const taskMonth = `${taskDate.getFullYear()}-${String(taskDate.getMonth() + 1).padStart(2, '0')}`;
                return taskMonth === selectedMonth;
            });
        }
        setFilteredTasks(filtered);

        // 合計稼働時間と合計金額を計算
        let totalMinutes = filtered.reduce((sum, task) => sum + task.hours * 60 + task.minutes, 0);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const cost = Math.floor((totalMinutes * (2000 / 60)));

        setTotalHours(hours);
        setTotalMinutes(minutes);
        setTotalCost(cost);
    }, [selectedMonth, allTasks]);

    const fetchTasks = useCallback(async () => {
        try {
            const response = await axios.get('/api/users/tasks');
            setAllTasks(response.data || []);
        } catch (error) {
            console.error('Error fetching tasks:', error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            } else {
                setAllTasks([]);
            }
        }
    }, [navigate]);

    const fetchClientInfo = useCallback(async () => {
        try {
            const response = await axios.get('/api/users/client-info');
            setClientName(response.data.companyName || '');
        } catch (error) {
            console.error('Error fetching client info:', error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            } else {
                setClientName('');
            }
        }
    }, [navigate]);

    const handleMonthChange = (e) => {
        const newMonth = e.target.value;
        setSelectedMonth(newMonth);
    };

    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');
        const user = JSON.parse(localStorage.getItem('user'));
        if (!sessionId || !user) {
            navigate('/login');
        } else {
            fetchTasks().catch(console.error);
            fetchClientInfo().catch(console.error);
        }
    }, [navigate, fetchTasks, fetchClientInfo]);

    useEffect(() => {
        filterTasks();
    }, [allTasks, filterTasks]);

    const getUniqueMonths = () => {
        const months = allTasks.map(task => task.taskDate.substring(0, 7));
        return [...new Set(months)].sort();
    };

    const getDisplayMonth = () => {
        if (!selectedMonth) return '全期間';
        const [year, month] = selectedMonth.split('-');
        return `${year}年${month}月`;
    };

    const handleLogout = async () => {
        try {
            await axios.post('/api/users/logout');
            localStorage.removeItem('sessionId');
            localStorage.removeItem('user');
            navigate('/login');
        } catch (error) {
            console.error('ログアウトに失敗しました:', error);
            alert('ログアウトに失敗しました。もう一度お試しください。');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.title}>{clientName ? `${clientName}様` : 'マイページ'}</h2>
                <div className={styles.headerButtons}>
                    <button className={styles.logoutButton} onClick={handleLogout}>
                        <FaSignOutAlt style={{ marginRight: '0.5rem' }} />
                        ログアウト
                    </button>
                    <Link to="/settings" className={styles.settingsButton} title="設定">
                        <FaCog />
                    </Link>
                </div>
            </div>
            
            <div className={styles.monthSelectorCard}>
                <div className={styles.monthSelector}>
                    <select
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        aria-label="月を選択"
                    >
                        <option value="">全期間</option>
                        {getUniqueMonths().map(month => (
                            <option key={month} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className={styles.taskCard}>
                {allTasks.length > 0 ? (
                    <div className={styles.tableWrapper}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>タスク名</th>
                                    <th>実施日</th>
                                    <th>稼働時間</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(selectedMonth ? filteredTasks : allTasks).map(task => (
                                    <tr key={task.taskId}>
                                        <td>{task.taskName}</td>
                                        <td>{task.taskDate}</td>
                                        <td>{formatTime(task.hours, task.minutes)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p>表示するタスクがありません。</p>
                )}
            </div>

            <div className={styles.summaryCard}>
                <div className={styles.summary}>
                    <p className={styles.summaryItem}>
                        <FaClock style={{ marginRight: '0.5rem' }} />
                        {getDisplayMonth()}の合計稼働時間: <span className={styles.summaryValue}>{formatTime(totalHours, totalMinutes)}</span>
                    </p>
                    <p className={styles.summaryItem}>
                        <FaYenSign style={{ marginRight: '0.5rem' }} />
                        {getDisplayMonth()}の合計金額: <span className={styles.summaryValue}>{totalCost.toLocaleString()}円</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MyPage;